import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-materials-view',
  templateUrl: './materials-view.page.html',
  styleUrls: ['./materials-view.page.scss'],
})
export class MaterialsViewPage implements OnInit {
  materials:any;
  constructor(
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    private httpClient:HttpClient
  ) { }

  ngOnInit() {
    this.materials = this.navParams.get('item');

  }
  closeModal() {
    this.modalController.dismiss();
  }
  addMaterial(){}

}
