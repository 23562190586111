import {Component, OnInit, Input} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {ToastController} from '@ionic/angular';
import {HttpClient} from "@angular/common/http";
import {environment} from './../../../environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-edit-working-hour',
    templateUrl: './edit-working-hour.page.html',
    styleUrls: ['./edit-working-hour.page.scss'],
})
export class EditWorkingHourPage implements OnInit {
    windowWidth: number = window.innerWidth;
    // "value" passed in componentProps
    @Input() value: number;
    wh: any;
    formSubmited: boolean = false;

    constructor(
        private modalController: ModalController,
        public toastController: ToastController,
        private navParams: NavParams,
        private httpClient: HttpClient
    ) {
        // componentProps can also be accessed at construction time using NavParams
    }

    ngOnInit() {
        let wh = this.navParams.get('wh');
        wh.startTime = wh.startTime.slice(0, -5);
        wh.endTime = wh.endTime.slice(0, -5);
        this.wh = wh;
    }

    closeModal() {
        this.modalController.dismiss();
    }

    async saveModal() {
        if (this.wh.startTime.length > 20) {
            this.wh.startTime = this.wh.startTime.slice(0, -6);
        }
        if (this.wh.endTime.length > 20) {
            this.wh.endTime = this.wh.endTime.slice(0, -6);
        }
        if (this.wh.startTime != "" && this.wh.endTime != "" && this.wh.constructionSite != "" && this.wh.country && this.wh.country != "" && this.wh.typeOfWork && this.wh.typeOfWork != "") {
            this.formSubmited = true;
            this.httpClient.patch(`${environment.url}working-hours/` + this.wh.id, this.wh).subscribe(
                data => {
                    this.modalController.dismiss();
                },
                error => {
                    console.log("Error", error);
                    this.formSubmited = false;
                }
            );
        } else {
            const toast = await this.toastController.create({
                message: 'Vsa polja, razen opomb so obvezna.',
                duration: 2000
            });
            toast.present();
        }
    }
}
